export default {
  computed: {
    computedStyle() {
      return (theme, element) => {
        if (theme[element].reference && theme.textPresets) {
          return theme.textPresets.find(
            preset => preset.id === theme[element].reference
          );
        }
        return theme[element];
      };
    }
  }
};
