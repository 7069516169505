<template>
  <div
    :class="{ 'has-errors': hasErrors }"
    class="form-input text-input">
    <label
      v-if="label.length"
      :for="inputId"
      :class="theme ? null : labelClass"
      class="mb-2 control-label flex items-baseline"
      data-element="labelStyles"
      :style="labelStyles">
      <span v-html="label"></span>
      <span
        v-if="required"
        class="text-red">
        *
      </span>
      <a
        v-if="type === 'password'"
        class="underline typeset-8 cursor-pointer space-between ml-auto"
        @click="showHidePassword">
        {{
          showingPassword
            ? t("login-modal.form.hide-password")
            : t("login-modal.form.show-password")
        }}
      </a>
    </label>
    <div class="flex relative">
      <input
        :id="inputId"
        ref="input"
        data-element="textInput"
        :data-cy="inputId"
        :style="textInputTheme"
        :value="modelValue"
        :placeholder="placeholder"
        :type="dynamicFieldType"
        :min="minimumAttr"
        :max="maximumAttr"
        :maxlength="maxLength"
        :class="[
          theme ? 'themed-text-input' : 'form-control',
          { uppercase, bordered }
        ]"
        :inputmode="inputmode"
        :autocorrect="autocorrect"
        :autocomplete="autocomplete"
        :autocapitalize="autocapitalize"
        :step="step"
        @keypress="onlyNumber"
        @blur="$emit('blur')"
        @paste="checkPreventPaste"
        @input="handleInput($event.target.value)" />
      <fade-transition>
        <div
          v-if="status === 'success'"
          class="absolute right-3 top-3">
          <svg-icon
            class="text-green"
            width="16px"
            height="16px"
            name="tick" />
        </div>
        <div
          v-else-if="status === 'error'"
          class="absolute right-3 top-3">
          <svg-icon
            class="text-red"
            width="16px"
            height="16px"
            name="cross" />
        </div>
        <div
          v-else-if="status === 'loading'"
          class="loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            class="absolute right-3 top-3"
            width="20px"
            height="20px"
            viewBox="0 0 10 10"
            preserveAspectRatio="xMidYMid">
            <circle
              cx="5"
              cy="5"
              fill="none"
              stroke="#1e2525"
              stroke-width="1"
              r="3"
              stroke-dasharray="14.137166941154069 6.71238898038469">
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 5 5;360 5 5"
                keyTimes="0;1" />
            </circle>
          </svg>
        </div>
      </fade-transition>
    </div>
    <div
      v-if="hasErrors && !hideErrorMessage"
      data-cy="input-error-message"
      class="error-box mt-2">
      {{ inputErrors.join(", ") }}
    </div>
    <slide-transition>
      <p
        v-if="explanation.length"
        class="typeset-8 my-3">
        {{ explanation }}
      </p>
    </slide-transition>
  </div>
</template>

<script>
import SlideTransition from "@/app/javascript/components/transitions/SlideTransition.vue";
import FadeTransition from "@/app/javascript/components/transitions/FadeTransition.vue";
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";
import computedStyle from "@/components/planner/app/javascript/mixins/computedStyle";

export default {
  name: "TextInput",
  components: {
    SlideTransition,
    FadeTransition,
    SvgIcon
  },
  mixins: [computedStyle],
  props: {
    theme: {
      type: Object,
      default: () => {}
    },
    modelValue: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    min: {
      type: [String, Number],
      default: "2014-01-01",
      required: false
    },
    max: {
      type: [String, Number],
      default: new Date().toISOString().slice(0, 10),
      required: false
    },
    inputId: {
      type: String,
      required: true,
      default: ""
    },
    errors: {
      type: Array,
      default: () => []
    },
    explanation: {
      type: String,
      default: ""
    },
    labelClass: {
      type: String,
      required: false,
      default: "typeset-6"
    },
    maxLength: {
      type: Number,
      required: false,
      default: null
    },
    pattern: {
      type: RegExp,
      required: false,
      default: null
    },
    preventPaste: {
      type: Boolean,
      required: false,
      default: false
    },
    inputmode: {
      type: String,
      default: null
    },
    status: {
      type: String,
      default: null,
      validator: value => ["error", "success", "loading", null].includes(value)
    },
    loading: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    autocapitalize: {
      type: String,
      default: null,
      required: false
    },
    autocorrect: {
      type: String,
      default: null,
      required: false
    },
    autocomplete: {
      type: String,
      default: null,
      required: false
    },
    step: {
      type: Number,
      default: 1,
      required: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    numberOnlyInput: {
      type: Boolean,
      default: false
    },
    hideErrorMessage: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "errored-pattern", "blur"],
  data() {
    return {
      showingPassword: false
    };
  },
  computed: {
    dynamicFieldType() {
      if (this.type === "password") {
        return this.showingPassword ? "text" : "password";
      }
      return this.type;
    },
    textInputTheme() {
      if (this.theme) {
        return {
          borderRadius: this.theme.textInput.borderRadius,
          borderWidth: this.theme.textInput.borderWidth,
          fontFamily: this.theme.textInput.fontFamily,
          outline: this.theme.textInput.outline,
          letterSpacing: this.theme.textInput.letterSpacing,
          "--text-input-color": this.theme.textInput.color,
          "--text-input-hover-color": this.theme.textInput.hover.color,
          "--text-input-focus-color": this.theme.textInput.focus.color,
          "--text-input-background-color": this.theme.textInput.backgroundColor,
          "--text-input-hover-background-color":
            this.theme.textInput.hover.backgroundColor,
          "--text-input-focus-background-color":
            this.theme.textInput.focus.backgroundColor,
          "--text-input-border-color": this.theme.textInput.borderColor,
          "--text-input-hover-border-color":
            this.theme.textInput.hover.borderColor,
          "--text-input-focus-border-color":
            this.theme.textInput.focus.borderColor,
          "--text-input-box-shadow": this.theme.textInput.boxShadow,
          "--text-input-hover-box-shadow": this.theme.textInput.hover.boxShadow,
          "--text-input-focus-box-shadow": this.theme.textInput.focus.boxShadow,
          "--text-input-placeholder-color":
            this.theme.textInput.placeholder.color
        };
      }
      return null;
    },
    labelStyles() {
      if (this.theme) {
        return this.computedStyle(this.theme, "labelStyles");
      }
      return null;
    },
    inputErrors() {
      return this.errors || [];
    },
    hasErrors() {
      return this.inputErrors.length > 0;
    },
    minimumAttr() {
      if (this.type === "date" || this.type === "number") {
        return this.min;
      }

      return "";
    },
    maximumAttr() {
      if (this.type === "date" || this.type === "number") {
        return this.max;
      }

      return "";
    }
  },
  methods: {
    onlyNumber(event) {
      if (!this.numberOnlyInput) return;
      const char = String.fromCharCode(event.keyCode);
      if (!/[0-9]/.test(char)) {
        event.preventDefault();
      }
    },
    handleInput(value) {
      if (value.length && this.pattern && !this.pattern.test(value)) {
        if (this.pattern.test(value.toUpperCase())) {
          this.$refs.input.value = value.toUpperCase();
          this.$emit("update:modelValue", value.toUpperCase());
        } else {
          this.$emit("errored-pattern");
        }
      } else if (this.type === "number") {
        this.$emit("update:modelValue", parseFloat(value));
      } else {
        this.$emit("update:modelValue", value);
      }
    },
    checkPreventPaste(e) {
      if (this.preventPaste) {
        e.preventDefault();
      }
    },
    focus() {
      this.$refs.input.focus();
    },
    showHidePassword() {
      this.showingPassword = !this.showingPassword;
      return this.showingPassword;
    }
  }
};
</script>

<style lang="scss" scoped>
.themed-text-input {
  // Set some defaults, in case these aren't set in the custom theme.
  --text-input-color: #343434;
  --text-input-hover-color: #343434;
  --text-input-focus-color: #343434;
  --text-input-background-color: #efefef;
  --text-input-border-color: #efefef;
  --text-input-hover-background-color: #ffffff;
  --text-input-hover-border-color: #121212;
  --text-input-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Arial, sans-serif;
  --text-input-box-shadow: inset 0 2px 4px 0 rgba(0, 37, 84, 0.08);

  color: var(--text-input-color);
  background-color: var(--text-input-background-color);
  border-color: var(--text-input-border-color);
  box-shadow: var(--text-input-box-shadow);

  // Interaction styles (hover/focus) must use css variables
  &:hover {
    color: var(--text-input-hover-color);
    background-color: var(--text-input-hover-background-color);
    border-color: var(--text-input-hover-border-color);
    box-shadow: var(--text-input-hover-box-shadow);
  }

  &:focus {
    color: var(--text-input-focus-color);
    background-color: var(--text-input-focus-background-color);
    border-color: var(--text-input-focus-border-color);
    box-shadow: var(--text-input-focus-box-shadow);
  }

  &::placeholder {
    color: var(--text-input-placeholder-color);
  }
}
</style>
